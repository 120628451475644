<template>
  <div>
    <div class="row">

      <div class="col-md">
        <Card :title="'Ubah Laporan Lab'">
          <template v-slot:body>
            <Form
              :form="form"
              purpose="edit"
              :route="`lab-reports/${$route.params.id}`"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>


import Form from '@/component/lab-reports/Form.vue'
import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: '',
        patient_id: '',
        doctor_id: '',
        lab_template_id: '',
        report: '',
        lab_category_id: '',
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get(`lab-reports/${this.$route.params.id}`)
      // If Data Not found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('lab-report/list')
      } else {
        console.log(this.form)
        this.form['_method'] = "put"
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab" },
      { title: "Edit Laporan" },
    ])
    // Get Data
    this.get()
  },

}
</script>
